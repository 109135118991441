@import './../../vars';

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.list {
    list-style: none;
    font-size: $font-size;
    margin: 0 0 $spacer-large;
}

@media (min-width: $tablet) {
    .root {
        flex-direction: row;
    }
}
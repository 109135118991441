@import '../../../vars';

.ReactGridGallery {
    display: flex;
    height: 100%;
}

.ReactGridGallery > div {
    justify-content: space-around;
    align-content: center !important;
}

.ReactGridGallery_tile {
    background: $color-background-content-1 !important;
    margin: $spacer-large !important;
}

.ReactGridGallery_tile-viewport {
    width: 130px !important;
    height: 130px !important;
    transition: all .25s ease-in;
    
    &:hover {
        transform: scale(1.1);
    }
}

.ReactGridGallery_tile-viewport img {
    margin-left: 0 !important;
    border-radius: 50%;
    width: 100% !important;
    height: 100% !important;
   
    
    &:hover {
        border: 1px solid $color-background-navigation;
       
    }
}

@media (min-width: $tablet) {
    .ReactGridGallery_tile-viewport {
        width: 180px !important;
        height: 180px !important;
    }
}
@import "./../../../vars";

.root {
    position: relative;
    margin-left: $spacer;
    
    &::before {
        content: "<";
        position: absolute;
        left: -$spacer;
    }
}
@import "./../../../vars";

.root {
    padding: $spacer;
    flex-basis: 100%;
}

.first {
    background-color: $color-background-content-1;
}

.second {
    background-color: $color-background-content-2;
}

.noPadding {
    padding: 0;
}

.isReservation {
    padding-left: 0;
    padding-right: 0;
}

.full {
    flex-basis: 100%;
    background: linear-gradient(90deg, $color-background-content-1 0%, $color-background-content-1 50%, $color-background-content-2 50%);
 
}

.isHome {
    background: rgba($color-background-content-2, .5) url('./../../assets/mood-image1-bg.jpg') no-repeat;
    background-size: cover;
    background-position: bottom center;
}

.isFullColor {
    background: $color-background-content-1;
}

@media (min-width: $tablet) {
    .root {
        flex-basis: 50%;
        padding: $spacer-large;
    }
    
    .full {
        flex-basis: 100%;
    }
    
    .isHome {
        background-position: center;
    }
    
    
    .isReservation {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: $desktop) {
    .root {
        min-height: 850px;
        padding-top: 189px;
       
    }
    
    .noPadding {
        padding: 0;
    }
}

@media (min-width: $wide) {
    .root {
        padding-top: 288px;
    }
    
    .noPadding {
        padding: 0;
    }
}

$color-text-primair: #fff;
$color-text-secundair: #323232;
$color-text-tertiair: #c3c5c7;

$color-background-main: #bcbec0;
$color-background-content-1: #727272;
$color-background-content-2: $color-text-secundair;
$color-background-navigation: $color-text-primair;

$color-house-style: #6dcff6;

$spacer: 16px;
$spacer-half: calc($spacer / 2);
$spacer-large: $spacer * 1.5;
$spacer-xlarge: $spacer-large * 2;

$font-size: 16px;

$border: solid 2px $color-background-content-2;

$tablet: 768px;
$desktop: 1024px;
$wide: 1200px;
$xwide: 1600px;

$transitions: ease-in-out .25s;
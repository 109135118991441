@import '../../../vars';

.root {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid $color-text-primair;
    background: $color-background-content-1;
    color: $color-text-secundair;
    z-index: 1000;
    max-width: 1488px;
    width: 100%;
    padding: $spacer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button {
    background-color: $color-house-style;
    color: $color-text-secundair;
    border: 1px solid $color-text-primair;
    padding: $spacer-half;
    font-size: $font-size;
    text-transform: lowercase;
    cursor: pointer;
}
@import '../../../vars';

.root {
    margin: 0 0 $spacer-large;
    font-size: $font-size;
    text-transform: lowercase;
    width: 100%;
    
    td {
        padding: 0 0 $spacer-half;
        vertical-align: bottom;
    }
    
    tr td:first-child {
        padding-right: $spacer;
        width: 40%;
    }
}
@import '../../../vars';

.root {
    display: block;
    background-color: rgba(109, 207, 246, .6);
    font-size: $font-size;
    padding: $spacer;
    order: 2;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.listItem {
    margin-bottom: $spacer-half;
}

.link {
    font-weight: bold;
    color: $color-text-primair;
    text-decoration: none;
    border-bottom: 1px solid $color-background-navigation;
    transition: border $transitions;
    
    &:hover {
        border-bottom: none;
    }
}

.text {
    display: block;
}

.agendaTitle {
    font-size: $font-size;
    font-weight: bold;
}

@media (min-width: $desktop) {
    .root {
        position: absolute;
        left: 0;
        bottom: $spacer * 5;
        width: 250px;
    }
}
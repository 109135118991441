@import './_vars.scss';

@import-normalize;
@import url(https://fonts.googleapis.com/css?family=Raleway:300,700,500);

body {
    background: $color-background-main;
    color: $color-text-primair;
    font-family: "Raleway", "Trebuchet MS", sans-serif !important;
    font-size: 62.5%;
    margin: 0;
    line-height: 2.4em;
    font-weight: 300;
}

*, *::before, *::after {
    box-sizing: border-box;
}

@import '../../../vars';

.root,
.root:visited,
.root:active {
    text-decoration: none;
    border-bottom: 1px solid $color-background-navigation;
    color: $color-text-primair;
    transition: border $transitions;
    
    &:hover {
        text-decoration: none;
        border-bottom: none;
    
        .linkIcon {
            filter: grayscale(0);
            opacity: 100%;
        }
    }
}

.linkIcon {
    filter: grayscale(100%);
    opacity: 50%;
    transition: all $transitions;
}

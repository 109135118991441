@import '../../vars';

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.note {
    margin-bottom: $spacer-large;
    color: $color-house-style;
}

@media (min-width: $tablet) {
    .root {
        flex-direction: row;
    }
}
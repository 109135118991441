@import '../../vars';

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    *:not(h1) {
        text-transform: none;
    }
}

.subHeader {
    font-size: $font-size;
}

.list {
    font-size: $font-size;
    border: 1px solid $color-background-navigation;
    padding: $spacer-half;
    
    dt {
        font-weight: bold;
    }
    
    dd {
        margin: 0 0 $spacer $spacer;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (min-width: $desktop) {
    .root {
        flex-direction: row;
    }
}
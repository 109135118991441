@import '../../../vars';

.root {
    font-size: 27px;
    font-weight: 300;
    text-transform: lowercase;
    margin: 0 0 $spacer-half;
}

.isHome {
    font-size: 24px;
    text-transform: none;
    margin-bottom: $spacer-large;
}

.isBold {
    font-weight: bold;
}

.underlined {
    padding-bottom: $spacer-half;
    border-bottom: 1px solid $color-text-primair;
}

.notUnderlined {
    padding-top: $spacer-half;
}

.noMargin {
    margin-bottom: 0;
}

.empty::before {
    content: "\00a0";
}
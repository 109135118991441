@import '../../../vars';

.root {
    text-decoration: underline;
    color: $color-text-primair;
    display: block;
    font-size: $font-size;
    line-height: normal;
    
    &:hover {
        text-decoration: none;
    }
}

.logo {
    width: auto;
    max-width: 100%;
    max-height: 50px;
    height: auto;
}

@import '../../../vars';

.root {
    background-color: $color-background-navigation;
    color: $color-text-secundair;
    display: flex;
    padding: $spacer;
    height: $spacer * 6;
}

.logo {
    padding: 0;
    margin: 0 $spacer-large 0 0;
    flex-basis: calc(15% + #{$spacer-large});
    flex-shrink: 1;
    flex-grow: 0;
    text-align: center;
    
    img {
        width: auto;
        max-width: 2 * $spacer-large;
        height: 100%;
    }
}

.googleMaps {
    display: none;
}

@media (min-width: $desktop) {
    .root {
        display: block;
        flex-basis: 25%;
        padding-left: $spacer-large;
        padding-top: $spacer-large;
        height: auto;
    }
    
    .logo {
        width: calc(100% - #{$spacer-xlarge});
        margin-bottom: $spacer-xlarge;
        
        img {
            max-width: 145px;
        }
    }
    
    .googleMaps {
        margin: 0 0 $spacer-xlarge;
        padding: 0;
        width: calc(100% - #{$spacer-xlarge});
        text-align: center;
        display: block;
    }
}

@media (min-width: $desktop) {
    .root {
        padding-left: $spacer-xlarge;
        padding-top: $spacer-xlarge;
    }
}
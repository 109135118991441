@import '../../vars';

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 75vh;
}

.iframe {
    width: 100%;
    height: 90%;
    margin-top: $spacer;
}

.title {
    margin-left: $spacer;
    margin-right: $spacer;
}

@media (min-width: $tablet) {
    .root {
        flex-direction: row;
    }
}

@media (min-width: $desktop) {
    .root {
        min-height: auto;
    }
}
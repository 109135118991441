@import '../../../vars';

.root {
    display: flex;
    text-decoration: none;
    border-bottom: 1px solid $color-text-primair;
    color: $color-text-primair;
    
    &:hover .title {
        text-decoration: none;
    }
    
    &:not(:last-of-type) {
        margin-bottom: $spacer-xlarge;
    }
}

.content {
    flex-grow: 1;
   
}

.text {
    text-transform: unset;
}

.title {
    display: inline-block;
    text-decoration: underline;
    font-size: 21px;
    line-height: 21px;
    text-transform: unset;
}

.pic {
    padding: 0;
    margin: 0;
    width: 100px;
    height: 100px;
}

.image {
    border-radius: 50%;
}
@import '../../vars';

.root {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
}

@media (min-width: $desktop) {
    .root {
        flex-direction: row;
    }
}
@import '../../vars';

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.list {
    list-style: none;
    font-size: $font-size;
    margin: $spacer 0;
    padding: 0;
}

.listItem {
    padding-left: $spacer;
    position: relative;
    
    &::before {
        content: "-";
        position: absolute;
        left: 0;
        top: 0;
    }
}

.line {
    height: 1px;
    padding: 0;
    margin: $spacer-large 0;
    border: none;
    background-color: $color-text-primair;
}

.underlined {
    border-bottom: 1px solid $color-background-navigation;
    margin-bottom: $spacer;
    padding: 0;
    
    &::before {
        display: none;
    }
}

@media (min-width: $tablet) {
    .root {
        flex-direction: row;
    }
}
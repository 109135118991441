@import './_vars.scss';

.root {
    max-width: 1488px;
    margin: 0 auto;
    box-shadow: 0 7px 10px rgba(50, 50, 50, 0.75);
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-basis: 100%;
    min-height: 50vh;
}

@media (min-width: $desktop) {
    .root {
        flex-direction: row;
    }
    
    .content {
        min-height: auto;
    }
}

@media (min-width: $wide) {
    .root {
        margin: $spacer-xlarge auto $spacer-large;
        width: calc(100% - #{$spacer-xlarge});
    }
}
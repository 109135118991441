@import '../../vars';

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
}

.listBlock {
    flex-basis: 50%;
    
    &:first-of-type {
        margin-right: $spacer-large;
        flex-basis: calc(50% - #{$spacer})
    }
}

.list {
    font-size: $font-size;
    list-style: none;
    padding-left: $spacer-large;
}

@media (min-width: $tablet) {
    .root {
        flex-direction: row;
    }
}
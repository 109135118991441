@import '../../../vars';

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.links {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.block {
    flex-basis: 50%;
    margin-bottom: $spacer-xlarge;
    padding: 0 $spacer;
    text-align: center;
}

@media (min-width: $desktop) {
    .root {
        flex-direction: row;
    }
}

@media (min-width: $wide) {
    .block {
        flex-basis: calc(100% / 3);
    }
}
@import '../../../vars';

.root {
    width: calc(100% - #{$spacer-xlarge});
    margin-bottom: $spacer;
    text-align: right;
}

.list {
    margin: 0 0 $spacer-large;
    padding: 0;
    list-style: none;
    font-size: 27px;
}

.item {
    margin-bottom: $spacer;
}

.link {
    color: $color-text-secundair;
    text-decoration: none;
    text-transform: lowercase;
    transition: all $transitions;
    
    &:hover {
        color: $color-text-tertiair;
    }
}

.active {
    color: $color-text-tertiair
}

.itemSmall {
    font-size: 16px;
    margin-bottom: 0;
}

.social {
    position: absolute;
    left: 50%;
    top: $spacer;
    transform: translateX(-50%);
}

.socialLink {
    width: $spacer-large;
    height: $spacer-large;
    display: inline-block;
    margin-right: $spacer;
    
    img {
        width: 100%;
        height: auto;
    }
    
    &:last-child {
        margin-right: 0;
    }
}

.subNav {
    font-size: 16px;
    list-style: none;
    padding-left: $spacer;
    margin-top: $spacer-half;
    display: none;
    
    li {
        margin-top: $spacer-half;
    }
}

.active + .subNav {
    display: block;
}

@media (orientation: portrait) {
    .list:not(.navigationMobile) .item {
        display: inline-block;
        padding-left: $spacer;
    }
}

@media (min-width: $desktop) {
    .root {
        text-align: left;
    }
    
    .social {
        position: static;
        transform: none;
        margin: 0;
    }
    
    .socialLink {
        width: $spacer;
        height: $spacer;
    }
}

.navigationMobile {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
    background-color: $color-background-navigation;
    text-align: center;
    padding: $spacer-large;
    box-shadow: 0 $spacer $spacer-large 0 $color-background-content-2;
    transition: all $transitions;
}

.checkbox {
    display: none;
}

.hamburger,
.hamburger::before,
.hamburger::after {
    transition: all $transitions;
    z-index: 15;
    cursor: pointer;
}

.hamburger {
    margin-top: $spacer-half;
}

.checkbox ~ .hamburger {
    width: $spacer-large;
    height: $spacer-large;
    display: inline-block;
    border-top: $border;
    border-bottom: $border;
    position: relative;
    
    &::before {
        content: "";
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        right: 0;
        height: 2px;
        border-bottom: $border;
    }
    
    &::after {
        content: "menu";
        position: absolute;
        bottom: -110%;
        left: -2px;
        font-weight: bold;
    }
}

.checkbox:checked ~ .hamburger {
    overflow: hidden;
    border: none;
    
    &::before {
        transform: rotate(-45deg);
    }
    
    &::after {
        content: "";
        transform: rotate(45deg);
        height: 2px;
        top: calc(50% - 1px);
        left: 0;
        right: 0;
        bottom: auto;
        border-bottom: $border;
    }
}

.checkbox ~ .navigationMobile {
    opacity: 0;
    z-index: -1;
    pointer-events: none;
}

.checkbox:checked ~ .navigationMobile {
    opacity: 1;
    z-index: 10;
    pointer-events: auto;
}
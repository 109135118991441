@import './../../../vars';

.root {
    color: $color-text-primair;
    text-transform: lowercase ;
    font-size: $font-size;
    line-height: 21px;
    margin: 0 0 $spacer-large;
    
    a {
        color: $color-text-primair;
        text-decoration: none;
        border-bottom: 1px solid $color-background-navigation;
        transition: border $transitions;
        
        &:hover {
            text-decoration: none;
            border-bottom: none;
        }
    }
}

.promo {
    color: $color-house-style;

    a, a:visited {
        color: $color-house-style;
        border-color: $color-house-style;
    }

    a:hover,
    a:hover:visited {
        color: $color-text-primair;
        border-color: $color-text-primair;
    }
}

.isHome {
    font-size: 24px;
    text-transform: none;
    line-height: 29px;

    a:hover {
        border-bottom: 1px solid $color-house-style;
        color: $color-house-style;
    }
}

.noMargin {
    margin: 0;
}
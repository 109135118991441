@import '../../vars';

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.caps {
    text-transform: none;
}

.big {
    font-size: 21px;
}

@media (min-width: $tablet) {
    .root {
        flex-direction: row;
    }
}
@import '../../../vars';

.root {
    margin: 0 0 $spacer-large;
    border-bottom: 1px solid $color-text-primair;
}

.link {
    font-size: 27px;
    color: $color-text-primair;
    text-decoration: none;
    margin-right: $spacer;
    
    &:hover {
        border-bottom: 1px dotted $color-text-primair;
    }
}
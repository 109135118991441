@import '../../vars';

.root {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
}

.list {
    margin: $spacer-large 0;
    font-size: 24px;
    text-transform: none;
    line-height: 29px;
    
    li {
        margin-bottom: $spacer-half;
    }
}

.note {
    font-size: 20px;
}

@media (min-width: $desktop) {
    .root {
        flex-direction: row;
    }
}
@import './../../../vars';

.root {
    margin: 0;
    padding: 0;
    flex-basis: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex-grow: 0;
    display: none;
}

@media (max-width: ($tablet - 1px)) {
    .showPicture {
        display: block;
        height: auto;
        min-height: 400px;
        background-position: top center;
        order: 2;
    }
}

@media (min-width: $tablet) {
    .root {
        flex-basis: 50%;
        display: block;
    }
}

@media (min-width: $desktop) {
    .root {
        min-height: 850px;
        display: block;
    }
}